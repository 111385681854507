export default{
    typeProfileSynthesis: 'Type + Profile Synthesis',
    combo: 'Combination',
    progress: 'Your Analysis Progress',
    progressCheckbox: 'I\'ve completed this section 👍',
    nextSection: 'Next',
    prevSection: 'Previous',
    progressSection: '{completed} of {total} sections completed',
    continue: 'Continue Analysis',
    foundation:{
        title: 'Foundational Analysis',
        tabs:{
            0: 'Introduction',
            1: 'Type, Strategy and Authority',
            2: 'Conditioning',
            3: 'Life-Force',
            4: 'Imprint',
            5: 'Role, Purpose and Direction'
        },
        tabExplanations: {
            0: '<p>' +
                'The Human Design System is the awareness tool of our time. Here’s what you can discover through your individual design.'+
                '</p>',
            1: '<p>' +
                'The unique way you are designed to interact and make decisions in alignment with your authentic self. ' +
                'Includes content about <strong>Type, Strategy and Inner Authority.</strong>' +
                '</p>',
            2: '<p>' +
                'Where you are susceptible to influence and pressure but also where your potential for wisdom lies. ' +
                'Includes content about <strong>Open Centers.</strong>' +
                '</p>',
            3: '<p>' +
                'The way energy flows through your consistent, fixed and reliable qualities. ' +
                'Includes content about <strong>Definition, Defined Centers, Channels, and Circuitry.</strong>' +
                '</p>',
            4: '<p>' +
                'Your conscious and unconscious characteristics. Includes content about <strong>Gates, Planets, Personality and Design.</strong>' +
                '</p>',
            5: '<p>' +
                'Your potential as a result from experimenting with and living your Human Design. ' +
                'Includes content about <strong>Profile, Nodal Polarities, and Incarnation Cross.</strong>' +
                '</p>'
        }
    },
    advanced: {
        title: 'Advanced Analysis',
        tabs:{
            0: 'Reach Your Cognitive Potential',
            1: 'Nourish Your Body & Brain',
            2: 'Recognize Your Path',
            3: 'How You View Life Uniquely',
            4: 'How Your Mind Conceptualizes',
            5: 'Variable Integration',
        },
        tabExplanations: {
            0: '<p>An introduction to Advanced Analysis, and the importance of accurate birth time and following your Strategy & Authority before experimenting with this knowledge.</p>',
            1: '<p>The particular Dietary Regimen that enables your body to absorb nutrients properly—enhancing the entire body’s wellbeing and the brain’s functioning. Includes content about Design Internal Variable, Color and Tone.</p>',
            2: '<p>The correct Environment harmonizes with your body’s resonance to outer conditions and is critical for longevity, the quality of life, and for developing your Perspective. Includes content about Design External Variable, Color and Tone.</p>',
            3: '<p>Your Perspective develops your awareness of how you uniquely see life experiences. It is how you gain wisdom and provides you with signposts to notice when you get lost in distraction. Includes content about Personality External Variable, Color and Tone.</p>',
            4: '<p>Motivation and Awareness determines the unique way you think and conceptualize, and the expression of what you have learned through your life. Includes content about Personality Internal Variable, Color and Tone.</p>',
            5: '<p>Variable Integration is the synthesis of all four Variables, and through experimentation and practice, what it is to live out your full potential as a unique being.</p>'
        }
    },
    work_success: {
        title: 'Work Success Analysis',
        tabs: {
            0: 'Work Success Analysis',
            1: 'Type',
            2: 'Definition',
            3: 'Penta Gates',
            4: 'Penta Channels',
            5: 'Profile',
            6: 'Profit Centers & Money Lines'
        }
    },
    misc: {
        title: 'Miscellaneous Analysis'
    },

    connection:{
        title: 'Connection Analysis',
        tabs: {
            0: 'Get Started',
            1: 'Connection Theme',
            2: 'Electromagnetic Connections',
            3: 'Dominance',
            4: 'Compromise',
            5: 'Companionship',
            6: 'Gates of Love',
        },
        activation: {
            dominance: 'Dominance channel activation',
            compromise: 'Compromise channel activation'
        },
        gatesOfLove: {
            transcendence: 'Transcendent',
            personal: 'Personal'
        }
    },
    noLoveGates: 'No electromagnetic connections involving Love Gates found.',
    loveGates: {
        undefined: 'Love Gate in an Undefined Center',
        defined: 'Love Gate in a Defined Center'
    }


}