<template>

  <div class="grid grid-cols-md-2 gap-10 pt-1 pb-5">

    <div class="">
      <checkout-product v-for="product in products.singles" :key="product.key" :product="product"
                        @addNewProduct="toggleNewProduct"/>
    </div>

    <single-purchase :create-new="true" />


    <side-extended-panel id="select-chart" :title="$t('billing.checkout.selectChart')" :visible="addChart.showSideChart"
                         @close="addChart.showSideChart = false">
      <chart-sidebar-selector :key="addChart.product.key" :product="addChart.product"
                              @addToCart="addToCart"/>
    </side-extended-panel>

  </div>

</template>

<script>
import SinglePurchase from "../single/SinglePurchase";
import CheckoutProduct from "./CheckoutProduct";
import {mapState} from "vuex";
import SideExtendedPanel from "../../structure/SideExtendedPanel";
import ChartSidebarSelector from "./ChartSidebarSelector";
import {createSingleCartItem} from "@/helpers/checkout_helper";

export default {
  components: {ChartSidebarSelector, SideExtendedPanel, CheckoutProduct, SinglePurchase},

  computed: {
    ...mapState({
      products: state => state.checkout.products,
      personalChart: state => state.charts.personalChart,
      cartItems: state => state.checkout.single_cart_items
    })
  },

  data() {
    return {
      addChart: {
        showSideChart: false,
        product: {
          key: null
        },
        chartId: null
      },
      urlApplied: false
    }
  },

  watch: {
    products(){
      this.handleUrlAddToCart();
    },
    personalChart(){
      this.handleUrlAddToCart();
    },
    cartItems(val) {
      if (!this.$store.state.checkout.serverSynced) return;

      this.axios.post('/cart', {
        type: 1,
        items: val.map(x => ({key: x.key, quantity: x.quantity, chart_id: x.chart_id, price: x.price, name: `${x.product.name} - (${x.name})` }))
      }).catch(() => {})
    }
  },
  mounted(){
    this.handleUrlAddToCart();
  },
  methods: {
    toggleNewProduct(product) {
      this.addChart.showSideChart = true;
      this.addChart.product = product;
    },
    addToCart(chart) {
      this.$store.commit('checkout/addSingleCartItem', createSingleCartItem(chart, this.addChart.product));
      this.addChart.showSideChart = false;
    },


    handleUrlAddToCart() {
      if(this.urlApplied) return;

      const hasUnlockMain = this.$route.query.unlock_main;
      let category = this.$route.query.category;

      const isPersonal = ['advanced', 'foundational', 'work_success'].includes(category);
      const isConnection = category === 'partnership' || category === 'companionship';

      if (isConnection) category = 'companionship';
      if ((!hasUnlockMain && !isPersonal) && !isConnection) return;

      const product = this.products.singles.find(x => x.key === category);

      if (!this.personalChart && !this.personalChart.meta) return;
      if (!product) return;

      if (isConnection) {
        this.addChart.showSideChart = true;
        this.addChart.product = product;
        return;
      }

      this.axios.get(`/billing/unlocked-charts/availability/${this.personalChart.id}?purchase_type=${category}`)
          .then((res) => {
            const isAvailable = res.data;
            this.urlApplied = true;
            if (!isAvailable) return;

            if (isPersonal) {
              this.$store.commit('checkout/addSingleCartItem', createSingleCartItem({
                id: this.personalChart.id,
                name: this.personalChart.meta.name
              }, this.products.singles.find(x => x.key === this.$route.query.category)));
            }
          }).catch(() => {});
    }
  }
}
</script>