<template>
  <component :is="isCheckout ? 'b-card' : (!showSelect ? `router-link` : 'div')"
             :class="`
             ${isSearch ? 'search-origin cursor-pointer' : ''}
             ${index === 0 ? ( total === 1 ? 'only-first-elem' : 'first-elem') : ''}
             ${isCheckout ? 'py-0' :''}
             ${showSelect ? 'cursor-pointer': ''}
             `"
             :to="!showSelect ? `/charts/${chart.id}` : null"
             class="chart-library-card card search-card ease-in-out duration-300 w-100"
             @click="elementClicked"
  >
    <div :class="isCheckout ? 'p-0' :''" class="card-body">
      <div class="d-flex chart-details w-100 flex-shrink-0 align-items-center">
        <div v-if="!showSelect || isCheckout" class="initials-box float-left text-center cursor-pointer">
          {{ getInitials(chart.name, isCompanionshipChart) }}
        </div>
        <b-form-checkbox v-else
                         :id="`chart-item-${chart.id}`"
                         :checked="isSelected"
                         class="flex-shrink-0 d-flex justify-content-center"
                         style="margin:0 12px 0 5px"
                         @change="elementClicked"
        />
        <div class="d-inline d-f cursor-pointer w-25 chart-name">
          <div class="mb-0 font-weight-bold text-sm d-flex align-items-center gap-10">
            {{ chart.name }}
            <div class="unlock-states">
              <div v-if="isRaveChart">
                <span v-if="isUnlocked('foundational')"
                      v-b-tooltip.hover
                      :title="`Foundational Analysis Unlocked`"
                      class="icon-foundational-analysis foundational mr-2"
                >
                </span>
                <span v-if="isUnlocked('advanced')"
                      v-b-tooltip.hover
                      :title="`Advanced Analysis Unlocked`"
                      class="icon-advanced-analysis foundational"
                />
                <span v-if="isUnlocked('work_success')"
                      v-b-tooltip.hover
                      :title="`Work Success Analysis Unlocked`"
                      class="icon-v2-work-success foundational"
                />
              </div>

              <div v-if="isCompanionshipChart">
             <span v-if="isUnlocked('companionship')"
                   v-b-tooltip.hover
                   :title="`Partnership Analysis Unlocked`"
                   class="icon-companionship foundational mr-2"
             />
              </div>
            </div>
          </div>
          <span class="mbg-text-muted">{{ $t('documentTypes.' + chart.docType) }}</span>
        </div>
        <div v-if="type && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{ $t(`hd.constants.types.${chart.type}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.type') }}</span>
        </div>
        <div v-if="authority && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{
              +chart.authority === Authorities.NONE ?
                  $t(`hd.constants.authorities.empty.${chart.type}`)
                  :
                  $t(`hd.constants.authorities.${chart.authority}`)
            }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.authority') }}</span>
        </div>

        <div v-if="definition && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{ $t(`hd.constants.definitions.${chart.definition}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.definition') }}</span>
        </div>
        <div v-if="profile && isRaveChart" class="chart-attribute desktop-version">
          <p class="mb-0 text-sm">{{ $t(`hd.constants.profiles.${chart.profile}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.profile') }}</span>
        </div>

        <div v-if="isSearch" class="ml-auto search-icon">
          <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
            <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </div>

        <div class="ml-auto d-flex gap-6 ">
          <b-button v-if="showEditChart" class="ml-auto btn-xs" :variant="darkMode ? 'dark' : 'outline-dark'" @click.prevent="$emit('editChart', chart)">
            <span class="icon-edit"/>
          </b-button>
          <b-button v-if="!isSearch" class="ml-auto btn-xs" variant="primary" @click="$router.push(`/charts/${chart.id}`)">
            {{ $t('general.load') }}
          </b-button>
        </div>


      </div>


    </div>
  </component>
</template>

<style lang="scss">

.theme--dark {
  .chart-library-card {
    color: white;

    &:hover {
      color: white;
    }

    background: $bg-contrast-dark !important;
    box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05) !important;
  }

  .search-card:hover {
    border-color: white !important;
  }
}

.chart-library-card {
  color: initial;

  &:hover {
    color: initial;
  }

  border-radius: 0 !important;
  font-size: 10px !important;
  padding: 0;
  margin-bottom: 0.05rem;

  &.first-elem {
    border-radius: 10px 20px 0 0 !important;
  }

  &:last-of-type {
    border-radius: 0 0 10px 20px !important;
  }

  &.only-first-elem {
    border-radius: 10px 20px !important;
  }

  &.search-origin {
    margin-bottom: 0.2rem;
    border-radius: 5px 10px !important;
  }
}

.search-card {
  .initials-box {
    padding: 0.5rem 0;
    font-size: 0.6rem;
  }

  .search-icon {
    transition: all .5s ease-in-out;
  }

  border: 1px solid transparent !important;

  &:hover {
    border-color: $primary !important;

    .search-icon {

      margin-right: .5rem;
    }
  }
}

.initials-box {
  width: 50px;
  background: $bg-dark;
  color: #fff;
  border-radius: 5px 12px;
  padding: 1rem 0;
  margin-right: 1rem;
  font-weight: bold;
}

.chart-details {
  > div {
    flex-shrink: 0;
  }

  .chart-attribute {
    padding: 0 0.5rem;
    width: 12.5%;
    flex-shrink: 0;
    @media(max-width: 1024px) {
      display: none;
    }
  }

  .chart-name {
    font-size: 10px;
    @media(max-width: 1024px) {
      width: 60%;
    }
    width: 20%;
  }
}
</style>

<script>
import ChartUtil from "../../mixins/ChartUtil";
import Authorities from "../../helpers/rave/constants/authorities";

export default {
  mixins: [ChartUtil],
  props: {
    showSelect: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    index: Number,
    total: Number,
    chart: Object,
    isSearch: {
      type: Boolean,
      default: false
    },
    isCheckout: {
      type: Boolean,
      default: false
    },
    showEditChart: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Boolean,
      default: true
    },
    definition: {
      type: Boolean,
      default: true
    },
    authority: {
      type: Boolean,
      default: true
    },
    type: {
      type: Boolean,
      default: true
    },

  },
  computed: {
    Authorities() {
      return Authorities
    },
    isRaveChart() {
      return this.isPersonalChart(this.chart)
    },
    isCompanionshipChart() {
      return this.isConnectionChart(this.chart);
    },
  },
  data(){
    return {
      isClicking: false,
    }
  },
  methods: {
    isUnlocked(type) {
      return this.getUnlockStatus(this.chart)[type]
    },
    loadChart(chart) {
      if (this.isSearch) return;
      this.redirectTo('single-chart', null, {chartId: chart.id})
    },

    elementClicked() {
      if ((!this.showSelect || this.isCheckout) ) return this.$emit('chartItemClicked');
      if (this.isClicking) return;
      this.isClicking = true;

      setTimeout(() => {
        this.isClicking = false;
        this.$emit('chartSelected', {...this.chart, isSelected: !this.isSelected});
      }, 75);
    }

  }

}
</script>